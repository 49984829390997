<script lang="ts" setup></script>

<template>
  <div class="skeleton-genres">
    <v-skeleton size="small" radius="16px" w="248px" min-width="200px" h="180px" :rep="5" gap="16px" />
  </div>
</template>

<style lang="scss" scoped>
  .skeleton-genres {
    display: flex;
    margin-top: 64px;
    overflow: hidden;
  }
</style>
